import { h } from 'preact';
import register from 'preact-custom-element';
import { useState } from 'preact/hooks';
import Cookies from 'js-cookie';
import GridIcon from './gridIcon';
import CloseIcon from './closeIcon';
import SkillsIcon from './skillsIcon';

const Banner = ({paid = 'false'}) => {

  const isDismissed = Cookies.get('dismissed_app_switcher');

  const [showBanner, setShowBanner] = useState(!isDismissed)

  const isPaid = paid === "true";

  const handleDismiss = () => {
    Cookies.set('dismissed_app_switcher', true);
    setShowBanner(false)
  }

  const handleKeyPress = e => {
    // Trigger click handler when Enter or Space pressed
    // Use preventDefault to prevent Space from scrolling page
    if (['Enter', ' '].includes(e.key)) {
      e.target.click();
      e.preventDefault();
    }
  };

  return showBanner ? 
  <div>
    <style dangerouslySetInnerHTML={{ __html: `
      .banner {
        width: 100%;
        display: flex;
        justify-content: center;
        background: linear-gradient(90deg, #2947B2 0%, #2771E1 100%);
        color: white;
        padding: 16px 4px;
        position: relative;
        align-items: center;
        font-size: 14px;
        box-sizing: border-box;
      }
      .bannerText {
        display: flex;
        align-items: center;
        gap: 12px;
        line-height: 21px;
      }
          @media (max-width: 989px) {
            .bannerText {
              flex-direction: column;
              gap: 4px;
              padding: 0 24px;
              text-align: center;
            }
          }
      .bannerClose {
        width: 20px;
        height: 20px;
        cursor: pointer;
        position: absolute;
        top: 16px;
        right: 8px;
        margin-top: -8px;
        cursor: pointer;
      }

      .bannerText a {
        display: inline-block;
        margin-left: 4px;
      }
      .bannerText a,
      .bannerText a:hover,
      .bannerText a:active,
      .bannerText a:focus
      {
        color: #FFF;
      }
      .bannerText--divider {
        height: 18px;
        width: 1px;
        border-left: 1px solid rgba(255,255,255,0.5);
      }
          @media (max-width: 989px) {
            .bannerText--divider {
              display: none;
            }
          }
      .bannerText--groupTwo {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
      }


      @media (max-width: 420px) {
        .bannerText {
          display: block;
        }
        .bannerText > div {
          display: inline;
        }
        .bannerText span {
          vertical-align: middle;
          padding-top: 2px;
        }
        .bannerText--divider {
          display: inline-block;
          margin: 0 10px;
        }
      }
    `}} />
    <div class="banner">

      <div class="bannerText">
        <div class="bannerText--groupOne">
          A Cloud Guru is now part of the Pluralsight family. 
          <a href="https://acloudguru.com" target="_blank" rel="noopener noreferrer">Learn more</a>
        </div>
        <div class="bannerText--divider" />
        <div class="bannerText--groupTwo">
          {isPaid ? 'You can go to the' : 'Explore free content in the'}
          <span style="margin: 0 6px; display: inline-block;" aria-label="Skills">
            <SkillsIcon />
          </span>
          platform through the grid icon
          <span style="margin: 0 4px; display: inline-block;">
            <GridIcon  />
          </span>
          below
        </div>
      </div>

      <span class="bannerClose" onClick={handleDismiss} onKeyPress={handleKeyPress} tabIndex="0" aria-label="Dismiss banner">
        <CloseIcon />
      </span>

    </div> {/* /.banner */}
  </div>
  : null
};

register(Banner, 'wc-app-switcher-banner', ['paid'], {shadow: true});

export default Banner;
